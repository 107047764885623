import React from 'react'
import Layout from "../components/Layout/"
import Seo from "../components/seo"
import SocialLinks from "../components/SocialLinks"
import PageDisclaimer from '../components/PageDisclaimer'

const style = {
	wrapper: {
		maxWidth: "70rem",
		margin: "auto",
		padding: "2.5rem 0 5rem 0",
	},
}

const AboutPage = () => (
	<Layout page="about">
		<Seo title="work" />
		<section>
			<section className="about-wrapper" style={{...style.wrapper}}>
				<PageDisclaimer
					disclaimerBottomText="Sobre mim"
					styleTop={{  }}
				/>
				<p>Olá, tudo bem?</p> 

				<p>Meu nome é Danilo Gila e sou um <span style={{ fontWeight: "bold" }}>Fullstack Developer</span>. Estou localizado no Brasil, especificamente no Rio de Janeiro onde nasci e fui criado. </p>
				<p>Atualmente estou trabalhando como Fullstack Developer na Globo.com, focado em tecnologias envolvendo tanto Frontend e Backend tais como React, Javascript, Python e Golang. 
				Além dessas tecnologias citadas, trabalho com outras tecnologias que compõe esse ecossistema, tais como: Git, HTML5, CSS, Webpack, CI/CD, Docker, Linux e testes automatizados com Jest/React Testing Library</p>

				<p>No momento estou me graduando em Ciência da Computação pela Universidade Estadual da Zona Oeste (UEZO), onde estou tendo um aprimoramento contínuo em desenvolvimento técnico e gestão na área de tecnologia.</p>
				
				<p>Para me conhecer um pouco mais entre em contato comigo através das redes sociais a seguir:</p>
				<SocialLinks />
			</section>
		</section>
	</Layout>
)

export default AboutPage